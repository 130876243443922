import { FC, useContext } from "react";
import { IFilterPreset } from "types/options";
import { Flex } from "../Flex";
import { CustomTooltip } from "components/Tooltip";
import { useUserData } from "hooks/useUser";
import { PlanType } from "backend/utils/plan";
import { ResponsiveContext } from "lib/context";
import useFilters from "hooks/useFilters";
import { Geo } from "types/MapContext";

interface Props {
  geo: Geo;
}

const FiltersSets: FC<Props> = ({ geo }) => {
  const { filterPresets, selectedPreset, setFiltersFromPreset, resetFilters } =
    useFilters();
  const { isTabletOrMobile } = useContext(ResponsiveContext);
  const user = useUserData();

  function UpdateFilter(preset) {
    resetFilters();
    setTimeout(() => {
      setFiltersFromPreset(preset);
    }, 0);
  }

  return (
    <Flex
      wrap="wrap"
      css={(theme) => ({
        gap: `${theme.gap[2]}px ${theme.gap[1]}px`,
        padding: `${theme.padding.mediumSmall}px ${theme.padding.large}px`,
        borderBottom: isTabletOrMobile
          ? "none"
          : `1px solid ${theme.colors.inputBorder}`,
      })}
    >
      {filterPresets.map((preset) => (
        <CustomTooltip
          key={preset.label}
          {...(user.isPremiumOrBasic || user.isLoading ? { open: false } : {})}
          showArrow
          placement="topLeft"
          title={`To access ${preset.label} sign up as a VIP Member`}
        >
          <Flex
            justify="center"
            align="center"
            css={(theme) => ({
              height: 29,
              padding: `0 ${theme.padding.mediumSmall}px`,
              backgroundColor:
                selectedPreset === preset.label
                  ? theme.colors.primary
                  : theme.colors.primaryLight,
              borderRadius: theme.radii.large,
              cursor: "pointer",

              span: {
                color:
                  selectedPreset === preset.label
                    ? theme.colors.white
                    : theme.colors.primary,
                fontSize: theme.fontSizes.small,
                fontWeight: theme.fontWeights.bold,
              },
            })}
            onClick={() => UpdateFilter(preset)}
          >
            <span>{preset.label}</span>
          </Flex>
        </CustomTooltip>
      ))}
    </Flex>
  );
};

export default FiltersSets;
